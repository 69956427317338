import Testimonial from "./Testimonial";
import Projects from "./Projects";

const TestimonialProjects = () => {
  return (
    <>
      <Testimonial />
      <Projects />
    </>
  );
};

export default TestimonialProjects;
